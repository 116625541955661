<template>
<!--    <form class="flex flex-col gap-y-6"  @submit.prevent="submitForm">-->
<!--        <div class="flex gap-x-6">-->
<!--            <div class="flex-1">-->
<!--                <input v-model.trim="$v.form.name.$model" :class="{'border-red-500': $v.form.name.$error}" class="appearance-none outline-none px-3 py-3 w-full border border-gray-300 rounded" type="text" placeholder="Full Name">-->
<!--                <p class="text-red-500 text-sm mt-1" v-if="$v.form.name.$error">-->
<!--                    <span v-if="!$v.form.name.required">Full Name is required</span>-->
<!--                </p>-->
<!--            </div>-->
<!--            <div class="flex-1">-->
<!--                <input v-model.trim="$v.form.email.$model" :class="{'border-red-500': $v.form.email.$error}" class="appearance-none outline-none px-3 py-3 w-full border border-gray-300 rounded" type="text" placeholder="Email Address">-->
<!--                <p class="text-red-500 text-sm mt-1" v-if="$v.form.email.$error">-->
<!--                    <span v-if="!$v.form.email.required">Full Name is required</span>-->
<!--                    <span v-if="!$v.form.email.email">Email address must be valid</span>-->
<!--                </p>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="flex gap-x-8">-->
<!--            <div class="flex-1">-->
<!--                <input v-model.trim="$v.form.place.$model" :class="{'border-red-500': $v.form.place.$error}" class="appearance-none outline-none px-3 py-3 w-full border border-gray-300 rounded" type="text" placeholder="Place of work">-->
<!--                <p class="text-red-500 text-sm mt-1" v-if="$v.form.place.$error">-->
<!--                    <span v-if="!$v.form.place.required">Place of work is required</span>-->
<!--                </p>-->
<!--            </div>-->
<!--            <div class="flex-1">-->
<!--                <input v-model.trim="$v.form.phone.$model" :class="{'border-red-500': $v.form.phone.$error}" class="appearance-none outline-none px-3 py-3 w-full border border-gray-300 rounded" type="text" placeholder="Phone Number">-->
<!--                <p class="text-red-500 text-sm mt-1" v-if="$v.form.phone.$error">-->
<!--                    <span v-if="!$v.form.phone.required">Phone number is required</span>-->
<!--                </p>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div>-->
<!--            <input v-model.trim="$v.form.subject.$model" :class="{'border-red-500': $v.form.subject.$error}" class="appearance-none outline-none px-3 py-3 w-full border border-gray-300 rounded" type="text" placeholder="Subject">-->
<!--            <p class="text-red-500 text-sm mt-1" v-if="$v.form.subject.$error">-->
<!--                <span v-if="!$v.form.message.required">Subject is required</span>-->
<!--            </p>-->
<!--        </div>-->
<!--        <div>-->
<!--            <textarea rows="5" v-model.trim="$v.form.message.$model" :class="{'border-red-500': $v.form.message.$error}" class="appearance-none outline-none px-3 py-3 w-full border border-gray-300 rounded" placeholder="Type your message..."></textarea>-->
<!--            <p class="text-red-500 text-sm mt-1" v-if="$v.form.phone.$error">-->
<!--                <span v-if="!$v.form.message.required">Message is required</span>-->
<!--            </p>-->
<!--        </div>-->
<!--        <div class="text-center">-->
<!--            <button @click.prevent="submitForm" type="submit" class="px-8 rounded py-3 bg-green-400 text-white">Contact Us</button>-->
<!--        </div>-->
<!--    </form>-->
    <div class="flex justify-center">
        <a href="mailto:collaborate@thegreenroom.space?subject=I would like to find out more" class="px-8 rounded py-3 bg-green-400 text-white">Contact Us</a>
    </div>
</template>
<script>
    import { required, email } from 'vuelidate/lib/validators'
    export default {
        data(){
            return {
                form: {
                    name: '',
                    email: '',
                    place: '',
                    phone: '',
                    message: ''
                }
            }
        },
        validations: {
            form: {
                name: {
                    required
                },
                email: {
                    required,
                    email
                },
                place: {
                    required
                },
                phone: {
                    required
                },
                subject: {
                    required
                },
                message: {
                    required
                },
            }
        },     
        methods: {
            async submitForm(){

                try {

                    this.$v.$touch()

                    if (!this.$v.$invalid) {
                        await axios({
                            method: 'POST',
                            url: '',
                            data: this.form
                        })
                    }

                } catch (err) {
                    alert('something went wrong')
                }
            }
        } 
    }
</script>