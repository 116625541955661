<template>
    <header class="px-4 md:px-0 fixed top-0 left-0 right-0 py-4 z-10 bg-white shadow-md transform transition duration-500 ease-in-out" :class="{'-translate-y-full': !sticky}">
        <div class="container m-auto flex items-center relative">
            <a href="#">
                <svg class="h-8" viewBox="0 0 1855 250" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
                    <g id="Artboard1" transform="matrix(1.01904,0,0,1.64245,13.8005,-562.987)">
                        <rect x="-13.543" y="342.772" width="1819.9" height="151.791" style="fill:none;"/>
                        <g transform="matrix(0.98132,0,0,0.608845,244.479,167.922)">
                            <g transform="matrix(5.55556,0,0,5.55556,119.396,470.341)">
                                <path d="M0,-20.758L0,-18.726L-9.449,-18.726L-9.449,0L-12.006,0L-12.006,-18.726L-21.409,-18.726L-21.409,-20.758L0,-20.758Z" style="fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,160.736,470.34)">
                                <path d="M0,-20.858L0,-12.895C1.064,-13.738 2.183,-14.327 3.355,-14.661C4.526,-14.994 5.722,-15.16 6.937,-15.16C7.881,-15.16 8.747,-15.045 9.54,-14.81C10.333,-14.576 11.008,-14.261 11.572,-13.861C12.135,-13.461 12.576,-12.989 12.896,-12.446C13.214,-11.9 13.375,-11.318 13.375,-10.695L13.375,0L10.864,0L10.864,-10.329C10.864,-10.862 10.734,-11.313 10.476,-11.679C10.217,-12.046 9.875,-12.346 9.449,-12.577C9.023,-12.812 8.528,-12.977 7.965,-13.077C7.402,-13.179 6.816,-13.228 6.208,-13.228C5.478,-13.228 4.738,-13.123 3.993,-12.91C3.25,-12.7 2.58,-12.405 1.985,-12.028C1.392,-11.651 0.914,-11.206 0.547,-10.695C0.182,-10.185 0,-9.629 0,-9.029L0,0L-2.557,0L-2.557,-20.858L0,-20.858Z" style="fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,312.137,461.273)">
                                <path d="M0,-11.596C-0.852,-11.596 -1.643,-11.468 -2.374,-11.214C-3.106,-10.957 -3.744,-10.624 -4.291,-10.212C-4.84,-9.802 -5.282,-9.325 -5.615,-8.781C-5.952,-8.236 -6.148,-7.687 -6.208,-7.131L6.345,-7.131C6.315,-7.754 6.147,-8.336 5.841,-8.881C5.538,-9.425 5.112,-9.896 4.565,-10.296C4.016,-10.696 3.355,-11.012 2.58,-11.245C1.801,-11.48 0.942,-11.596 0,-11.596M-6.208,-5.398C-6.148,-4.732 -5.943,-4.071 -5.592,-3.415C-5.243,-2.761 -4.786,-2.182 -4.223,-1.684C-3.66,-1.184 -2.999,-0.777 -2.236,-0.467C-1.478,-0.157 -0.656,0 0.228,0C3.33,0 5.34,-0.99 6.252,-2.966L8.582,-2.966C8.337,-2.189 7.949,-1.494 7.416,-0.885C6.885,-0.272 6.247,0.238 5.501,0.648C4.754,1.06 3.932,1.376 3.034,1.599C2.138,1.82 1.201,1.932 0.228,1.932C-1.173,1.932 -2.436,1.715 -3.56,1.281C-4.688,0.85 -5.647,0.271 -6.438,-0.451C-7.229,-1.172 -7.837,-2.017 -8.263,-2.982C-8.689,-3.95 -8.902,-4.965 -8.902,-6.032C-8.902,-7.031 -8.689,-7.985 -8.263,-8.897C-7.837,-9.807 -7.229,-10.601 -6.438,-11.278C-5.647,-11.957 -4.696,-12.501 -3.585,-12.911C-2.473,-13.323 -1.217,-13.528 0.182,-13.528C1.52,-13.528 2.739,-13.346 3.835,-12.979C4.93,-12.613 5.857,-12.108 6.618,-11.463C7.379,-10.819 7.965,-10.053 8.376,-9.163C8.788,-8.274 8.993,-7.32 8.993,-6.298L8.993,-5.398L-6.208,-5.398Z" style="fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,423.673,412.401)">
                                <path d="M0,0.033C0,1.455 0.267,2.721 0.8,3.831C1.331,4.943 2.055,5.882 2.967,6.646C3.881,7.412 4.937,8.002 6.14,8.414C7.343,8.824 8.612,9.03 9.952,9.03C11.139,9.03 12.295,8.814 13.421,8.381C14.547,7.948 15.55,7.381 16.434,6.681C17.316,5.981 18.047,5.182 18.624,4.282C19.203,3.383 19.522,2.465 19.583,1.533L10.775,1.533L10.775,-0.433L22.003,-0.433L22.003,10.429L20.588,10.429L19.674,6.73C19.218,7.33 18.617,7.886 17.872,8.396C17.126,8.908 16.313,9.357 15.431,9.746C14.547,10.134 13.64,10.434 12.714,10.646C11.785,10.857 10.896,10.962 10.043,10.962C8.095,10.962 6.338,10.685 4.772,10.129C3.202,9.575 1.871,8.802 0.775,7.814C-0.319,6.825 -1.156,5.664 -1.734,4.331C-2.311,2.998 -2.601,1.566 -2.601,0.033C-2.601,-1.321 -2.32,-2.654 -1.757,-3.966C-1.194,-5.275 -0.381,-6.447 0.686,-7.48C1.75,-8.512 3.051,-9.345 4.588,-9.979C6.124,-10.612 7.881,-10.929 9.861,-10.929C11.504,-10.929 12.979,-10.762 14.287,-10.429C15.597,-10.096 16.73,-9.64 17.689,-9.063C18.649,-8.485 19.462,-7.797 20.13,-6.997C20.8,-6.198 21.318,-5.319 21.682,-4.365L18.898,-4.365C18.138,-5.919 17.026,-7.074 15.567,-7.83C14.107,-8.585 12.204,-8.963 9.861,-8.963C8.4,-8.963 7.067,-8.725 5.866,-8.246C4.663,-7.769 3.628,-7.118 2.762,-6.298C1.894,-5.475 1.217,-4.52 0.731,-3.432C0.244,-2.343 0,-1.188 0,0.033" style="fill:rgb(68,171,137);fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,573.047,468.857)">
                                <path d="M0,-14.627L2.557,-14.627L2.557,-12.361C3.379,-13.161 4.207,-13.782 5.045,-14.227C5.882,-14.671 6.832,-14.894 7.899,-14.894C8.079,-14.894 8.279,-14.883 8.491,-14.86C8.703,-14.837 8.902,-14.804 9.086,-14.76L9.086,-12.561C8.628,-12.671 8.202,-12.728 7.806,-12.728C6.983,-12.728 6.247,-12.617 5.591,-12.395C4.937,-12.172 4.382,-11.877 3.926,-11.511C3.471,-11.144 3.127,-10.722 2.899,-10.245C2.671,-9.768 2.557,-9.262 2.557,-8.73L2.557,0.267L0,0.267L0,-14.627Z" style="fill:rgb(68,171,137);fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,694.268,461.273)">
                                <path d="M0,-11.596C-0.852,-11.596 -1.643,-11.468 -2.375,-11.214C-3.106,-10.957 -3.744,-10.624 -4.291,-10.212C-4.84,-9.802 -5.282,-9.325 -5.615,-8.781C-5.952,-8.236 -6.148,-7.687 -6.208,-7.131L6.345,-7.131C6.315,-7.754 6.147,-8.336 5.841,-8.881C5.538,-9.425 5.112,-9.896 4.565,-10.296C4.016,-10.696 3.355,-11.012 2.58,-11.245C1.801,-11.48 0.942,-11.596 0,-11.596M-6.208,-5.398C-6.148,-4.732 -5.943,-4.071 -5.592,-3.415C-5.243,-2.761 -4.786,-2.182 -4.223,-1.684C-3.66,-1.184 -2.999,-0.777 -2.236,-0.467C-1.478,-0.157 -0.656,0 0.228,0C3.33,0 5.34,-0.99 6.252,-2.966L8.582,-2.966C8.337,-2.189 7.949,-1.494 7.416,-0.885C6.885,-0.272 6.247,0.238 5.501,0.648C4.754,1.06 3.932,1.376 3.034,1.599C2.138,1.82 1.201,1.932 0.228,1.932C-1.173,1.932 -2.436,1.715 -3.56,1.281C-4.688,0.85 -5.647,0.271 -6.438,-0.451C-7.229,-1.172 -7.837,-2.017 -8.263,-2.982C-8.689,-3.95 -8.902,-4.965 -8.902,-6.032C-8.902,-7.031 -8.689,-7.985 -8.263,-8.897C-7.837,-9.807 -7.229,-10.601 -6.438,-11.278C-5.647,-11.957 -4.696,-12.501 -3.585,-12.911C-2.473,-13.323 -1.217,-13.528 0.182,-13.528C1.52,-13.528 2.739,-13.346 3.835,-12.979C4.93,-12.613 5.857,-12.108 6.618,-11.463C7.379,-10.819 7.965,-10.053 8.375,-9.163C8.788,-8.274 8.993,-7.32 8.993,-6.298L8.993,-5.398L-6.208,-5.398Z" style="fill:rgb(68,171,137);fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,821.322,461.273)">
                                <path d="M0,-11.596C-0.852,-11.596 -1.643,-11.468 -2.374,-11.214C-3.106,-10.957 -3.744,-10.624 -4.291,-10.212C-4.84,-9.802 -5.282,-9.325 -5.615,-8.781C-5.952,-8.236 -6.148,-7.687 -6.208,-7.131L6.345,-7.131C6.315,-7.754 6.147,-8.336 5.841,-8.881C5.538,-9.425 5.112,-9.896 4.565,-10.296C4.016,-10.696 3.355,-11.012 2.58,-11.245C1.801,-11.48 0.942,-11.596 0,-11.596M-6.208,-5.398C-6.148,-4.732 -5.943,-4.071 -5.592,-3.415C-5.243,-2.761 -4.786,-2.182 -4.223,-1.684C-3.66,-1.184 -2.999,-0.777 -2.236,-0.467C-1.478,-0.157 -0.656,0 0.228,0C3.33,0 5.34,-0.99 6.252,-2.966L8.582,-2.966C8.337,-2.189 7.949,-1.494 7.416,-0.885C6.885,-0.272 6.247,0.238 5.501,0.648C4.754,1.06 3.932,1.376 3.034,1.599C2.138,1.82 1.201,1.932 0.228,1.932C-1.173,1.932 -2.436,1.715 -3.56,1.281C-4.688,0.85 -5.647,0.271 -6.438,-0.451C-7.229,-1.172 -7.837,-2.017 -8.263,-2.982C-8.689,-3.95 -8.902,-4.965 -8.902,-6.032C-8.902,-7.031 -8.689,-7.985 -8.263,-8.897C-7.837,-9.807 -7.229,-10.601 -6.438,-11.278C-5.647,-11.957 -4.696,-12.501 -3.585,-12.911C-2.473,-13.323 -1.217,-13.528 0.182,-13.528C1.52,-13.528 2.739,-13.346 3.835,-12.979C4.93,-12.613 5.857,-12.108 6.618,-11.463C7.379,-10.819 7.965,-10.053 8.376,-9.163C8.788,-8.274 8.993,-7.32 8.993,-6.298L8.993,-5.398L-6.208,-5.398Z" style="fill:rgb(68,171,137);fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,898.663,468.857)">
                                <path d="M0,-14.627L2.557,-14.627L2.557,-12.395C3.712,-13.394 4.945,-14.061 6.254,-14.394C7.562,-14.727 8.81,-14.894 9.998,-14.894C11.853,-14.894 13.3,-14.499 14.334,-13.711C15.369,-12.92 15.886,-11.828 15.886,-10.429L15.886,0.267L13.375,0.267L13.375,-10.162C13.375,-11.206 13.026,-11.933 12.325,-12.344C11.625,-12.756 10.652,-12.961 9.403,-12.961C8.46,-12.961 7.569,-12.843 6.734,-12.61C5.896,-12.377 5.173,-12.061 4.567,-11.662C3.956,-11.262 3.47,-10.806 3.106,-10.295C2.739,-9.783 2.557,-9.25 2.557,-8.696L2.557,0.267L0,0.267L0,-14.627Z" style="fill:rgb(68,171,137);fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,1047.99,415.363)">
                                <path d="M0,-0.966L8.856,-0.966C9.615,-0.966 10.345,-1.071 11.046,-1.283C11.746,-1.494 12.363,-1.776 12.897,-2.132C13.428,-2.488 13.847,-2.909 14.15,-3.398C14.454,-3.887 14.606,-4.408 14.606,-4.965C14.606,-5.52 14.463,-6.036 14.173,-6.514C13.884,-6.991 13.487,-7.402 12.986,-7.747C12.484,-8.091 11.874,-8.358 11.16,-8.547C10.445,-8.735 9.679,-8.829 8.856,-8.829L0,-8.829L0,-0.966ZM17.209,-4.965C17.209,-3.898 16.89,-2.909 16.251,-1.999C15.611,-1.089 14.668,-0.366 13.419,0.167C14.121,0.3 14.691,0.505 15.132,0.784C15.573,1.061 15.923,1.382 16.183,1.749C16.441,2.115 16.616,2.522 16.707,2.966C16.798,3.411 16.844,3.855 16.844,4.299L16.844,7.43C16.844,7.876 16.926,8.309 17.095,8.73C17.263,9.153 17.484,9.54 17.758,9.896L14.836,9.896C14.591,9.586 14.44,9.202 14.378,8.748C14.317,8.292 14.287,7.897 14.287,7.564L14.287,4.265C14.287,3.866 14.189,3.471 13.993,3.081C13.793,2.694 13.473,2.343 13.033,2.033C12.591,1.722 12.027,1.472 11.345,1.282C10.659,1.095 9.828,1 8.856,1L0,1L0,9.896L-2.557,9.896L-2.557,-10.862L8.856,-10.862C10.194,-10.862 11.381,-10.685 12.416,-10.329C13.451,-9.973 14.326,-9.517 15.041,-8.963C15.755,-8.407 16.297,-7.774 16.662,-7.064C17.026,-6.353 17.209,-5.653 17.209,-4.965" style="fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,1186.97,428.88)">
                                <path d="M0,0.033C0,0.832 0.153,1.581 0.456,2.281C0.761,2.981 1.187,3.593 1.734,4.114C2.283,4.636 2.958,5.047 3.765,5.346C4.572,5.646 5.478,5.797 6.482,5.797C7.486,5.797 8.391,5.646 9.198,5.346C10.003,5.047 10.689,4.636 11.252,4.114C11.814,3.593 12.248,2.981 12.553,2.281C12.856,1.581 13.01,0.832 13.01,0.033C13.01,-0.767 12.856,-1.517 12.553,-2.217C12.248,-2.917 11.814,-3.527 11.252,-4.05C10.689,-4.571 10.003,-4.988 9.198,-5.298C8.391,-5.609 7.486,-5.765 6.482,-5.765C5.478,-5.765 4.572,-5.609 3.765,-5.298C2.958,-4.988 2.283,-4.571 1.734,-4.05C1.187,-3.527 0.761,-2.917 0.456,-2.217C0.153,-1.517 0,-0.767 0,0.033M-2.511,0.033C-2.511,-1.033 -2.313,-2.033 -1.919,-2.966C-1.522,-3.899 -0.943,-4.716 -0.182,-5.414C0.577,-6.115 1.513,-6.67 2.625,-7.082C3.735,-7.492 5.021,-7.697 6.482,-7.697C7.942,-7.697 9.237,-7.492 10.362,-7.082C11.488,-6.67 12.439,-6.115 13.214,-5.414C13.993,-4.716 14.583,-3.899 14.996,-2.966C15.406,-2.033 15.611,-1.033 15.611,0.033C15.611,1.099 15.406,2.099 14.996,3.032C14.583,3.965 13.993,4.782 13.214,5.48C12.439,6.179 11.488,6.73 10.362,7.13C9.237,7.53 7.942,7.73 6.482,7.73C5.021,7.73 3.735,7.53 2.625,7.13C1.513,6.73 0.577,6.179 -0.182,5.48C-0.943,4.782 -1.522,3.965 -1.919,3.032C-2.313,2.099 -2.511,1.099 -2.511,0.033" style="fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,1315.04,428.88)">
                                <path d="M0,0.033C0,0.832 0.153,1.581 0.456,2.281C0.761,2.981 1.187,3.593 1.734,4.114C2.283,4.636 2.958,5.047 3.765,5.346C4.572,5.646 5.478,5.797 6.482,5.797C7.486,5.797 8.391,5.646 9.198,5.346C10.003,5.047 10.689,4.636 11.252,4.114C11.815,3.593 12.248,2.981 12.553,2.281C12.856,1.581 13.01,0.832 13.01,0.033C13.01,-0.767 12.856,-1.517 12.553,-2.217C12.248,-2.917 11.815,-3.527 11.252,-4.05C10.689,-4.571 10.003,-4.988 9.198,-5.298C8.391,-5.609 7.486,-5.765 6.482,-5.765C5.478,-5.765 4.572,-5.609 3.765,-5.298C2.958,-4.988 2.283,-4.571 1.734,-4.05C1.187,-3.527 0.761,-2.917 0.456,-2.217C0.153,-1.517 0,-0.767 0,0.033M-2.511,0.033C-2.511,-1.033 -2.313,-2.033 -1.919,-2.966C-1.522,-3.899 -0.943,-4.716 -0.182,-5.414C0.577,-6.115 1.513,-6.67 2.625,-7.082C3.735,-7.492 5.021,-7.697 6.482,-7.697C7.942,-7.697 9.237,-7.492 10.362,-7.082C11.488,-6.67 12.439,-6.115 13.214,-5.414C13.993,-4.716 14.584,-3.899 14.996,-2.966C15.406,-2.033 15.611,-1.033 15.611,0.033C15.611,1.099 15.406,2.099 14.996,3.032C14.584,3.965 13.993,4.782 13.214,5.48C12.439,6.179 11.488,6.73 10.362,7.13C9.237,7.53 7.942,7.73 6.482,7.73C5.021,7.73 3.735,7.53 2.625,7.13C1.513,6.73 0.577,6.179 -0.182,5.48C-0.943,4.782 -1.522,3.965 -1.919,3.032C-2.313,2.099 -2.511,1.099 -2.511,0.033" style="fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,1429.42,468.673)">
                                <path d="M0,-14.594L2.555,-14.594L2.555,-12.261C3.62,-13.305 4.747,-13.999 5.934,-14.343C7.122,-14.689 8.2,-14.861 9.173,-14.861C10.119,-14.861 11.038,-14.643 11.937,-14.21C12.833,-13.777 13.647,-13.094 14.378,-12.161C15.413,-13.228 16.539,-13.948 17.756,-14.327C18.973,-14.704 20.115,-14.894 21.179,-14.894C22.093,-14.894 22.929,-14.776 23.69,-14.543C24.45,-14.31 25.106,-13.994 25.654,-13.594C26.2,-13.195 26.628,-12.716 26.931,-12.161C27.235,-11.606 27.387,-11.006 27.387,-10.363L27.387,0.3L24.832,0.3L24.832,-9.729C24.832,-10.35 24.702,-10.867 24.443,-11.278C24.185,-11.69 23.857,-12.016 23.462,-12.261C23.066,-12.505 22.61,-12.677 22.093,-12.777C21.575,-12.877 21.074,-12.928 20.586,-12.928C19.948,-12.928 19.292,-12.828 18.624,-12.628C17.954,-12.428 17.354,-12.146 16.821,-11.779C16.288,-11.413 15.855,-10.978 15.52,-10.478C15.183,-9.978 15.017,-9.44 15.017,-8.863L15.017,0.3L12.46,0.3L12.46,-9.729C12.46,-10.35 12.332,-10.867 12.074,-11.278C11.815,-11.69 11.487,-12.016 11.092,-12.261C10.697,-12.505 10.263,-12.677 9.791,-12.777C9.317,-12.877 8.87,-12.928 8.444,-12.928C7.623,-12.928 6.846,-12.795 6.115,-12.528C5.386,-12.261 4.761,-11.923 4.244,-11.511C3.727,-11.101 3.316,-10.646 3.013,-10.145C2.708,-9.645 2.555,-9.173 2.555,-8.73L2.555,0.3L0,0.3L0,-14.594Z" style="fill-rule:nonzero;"/>
                            </g>
                        </g>
                        <g transform="matrix(0.724058,0,0,0.449231,-465.868,343.932)">
                            <g transform="matrix(5.55556,0,0,5.55556,741.268,93.3228)">
                                <path d="M0,6.623C0,8.61 -1.324,10.236 -3.13,10.778C-3.492,10.898 -3.912,10.958 -4.275,10.958C-4.696,10.958 -5.058,10.898 -5.419,10.778C-7.225,10.297 -8.549,8.61 -8.549,6.623C-8.549,6.262 -8.49,5.901 -8.43,5.54L-12.041,3.433C-12.223,3.614 -12.402,3.794 -12.644,3.914C-13.005,4.156 -13.426,4.336 -13.908,4.456C-14.27,4.577 -14.691,4.636 -15.052,4.636C-15.353,4.636 -15.595,4.636 -15.894,4.577C-16.016,4.577 -16.136,4.515 -16.195,4.515C-17.16,4.215 -18.003,3.614 -18.605,2.77C-19.929,0.784 -19.389,-1.866 -17.4,-3.19C-15.655,-4.335 -13.426,-4.093 -11.981,-2.708C-11.8,-2.527 -11.56,-2.287 -11.439,-2.047C-11.139,-1.626 -10.959,-1.204 -10.838,-0.721C-10.657,-0.12 -10.657,0.543 -10.778,1.205C-10.778,1.264 -10.838,1.385 -10.838,1.445L-7.225,3.553C-6.443,2.77 -5.419,2.348 -4.214,2.348C-3.011,2.348 -1.987,2.83 -1.205,3.553C-0.663,4.094 -0.242,4.757 -0.06,5.54C-0.06,5.901 0,6.262 0,6.623" style="fill:rgb(68,171,137);fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,804.49,247.196)">
                                <path d="M0,10.356C0.421,12.041 -0.242,13.968 -1.747,14.993C-2.469,15.473 -3.313,15.715 -4.154,15.715C-5.54,15.715 -6.923,15.052 -7.767,13.788C-8.49,12.704 -8.67,11.44 -8.369,10.294C-8.19,9.574 -7.767,8.851 -7.225,8.31C-7.046,8.127 -6.864,7.946 -6.622,7.827C-6.201,7.585 -5.84,7.345 -5.359,7.224L-5.359,3.071C-7.165,2.589 -8.49,0.902 -8.49,-1.083C-8.49,-1.444 -8.43,-1.808 -8.369,-2.169C-8.19,-2.951 -7.767,-3.612 -7.225,-4.154C-6.443,-4.938 -5.419,-5.359 -4.214,-5.359C-3.071,-5.359 -1.987,-4.878 -1.205,-4.154C-0.663,-3.612 -0.242,-2.951 -0.06,-2.169C0,-1.808 0.06,-1.444 0.06,-1.083C0.06,0.902 -1.264,2.529 -3.071,3.071L-3.071,7.285C-2.408,7.466 -1.747,7.827 -1.205,8.369C-1.024,8.55 -0.782,8.79 -0.663,9.032C-0.302,9.453 -0.121,9.874 0,10.356" style="fill:rgb(68,171,137);fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,929.593,130.45)">
                                <path d="M0,-6.261C0,-4.274 -1.326,-2.648 -3.132,-2.106C-3.492,-1.986 -3.914,-1.926 -4.275,-1.926C-4.698,-1.926 -5.059,-1.986 -5.421,-2.106C-6.141,-2.287 -6.745,-2.648 -7.285,-3.19L-10.899,-1.082C-10.778,-0.721 -10.778,-0.36 -10.778,0.002C-10.778,1.989 -12.102,3.614 -13.91,4.154C-14.271,4.275 -14.692,4.335 -15.053,4.335C-15.474,4.335 -15.836,4.275 -16.197,4.154C-18.003,3.674 -19.327,1.989 -19.327,0.002C-19.327,-0.36 -19.268,-0.721 -19.208,-1.082C-19.027,-1.866 -18.605,-2.527 -18.065,-3.069C-17.281,-3.851 -16.197,-4.274 -15.053,-4.274C-13.91,-4.274 -12.825,-3.792 -12.042,-3.069L-8.43,-5.177C-8.611,-5.538 -8.611,-5.9 -8.611,-6.261C-8.611,-6.622 -8.551,-6.983 -8.491,-7.344C-8.309,-8.127 -7.888,-8.79 -7.346,-9.331C-6.564,-10.114 -5.48,-10.536 -4.337,-10.536C-1.927,-10.596 0,-8.67 0,-6.261" style="fill:rgb(68,171,137);fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,741.268,237.827)">
                                <path d="M0,-6.021C0,-5.66 -0.06,-5.298 -0.119,-4.937C-0.302,-4.155 -0.723,-3.492 -1.264,-2.95C-2.047,-2.168 -3.071,-1.746 -4.275,-1.746C-5.419,-1.746 -6.503,-2.228 -7.285,-2.95L-10.897,-0.842C-10.717,-0.12 -10.717,0.603 -10.897,1.264C-11.078,1.986 -11.501,2.71 -12.041,3.252C-12.223,3.433 -12.402,3.614 -12.644,3.733C-13.366,4.215 -14.21,4.456 -15.052,4.456C-16.437,4.456 -17.821,3.794 -18.605,2.528C-19.929,0.543 -19.389,-2.106 -17.4,-3.431C-17.039,-3.673 -16.618,-3.913 -16.136,-4.034C-15.473,-4.214 -14.812,-4.274 -14.149,-4.094C-14.029,-4.094 -13.908,-4.034 -13.847,-4.034C-13.124,-3.854 -12.525,-3.492 -11.981,-2.95L-8.369,-5.058C-8.43,-5.419 -8.49,-5.779 -8.49,-6.14C-8.49,-8.129 -7.166,-9.753 -5.359,-10.296C-4.998,-10.416 -4.575,-10.477 -4.214,-10.477C-3.793,-10.477 -3.432,-10.416 -3.071,-10.296C-1.385,-9.693 0,-8.008 0,-6.021" style="fill:rgb(68,171,137);fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,805.157,23.75)">
                                <path d="M0,12.464C0,12.825 -0.06,13.187 -0.119,13.547C-0.302,14.33 -0.723,14.993 -1.264,15.535C-2.047,16.317 -3.071,16.739 -4.275,16.739C-5.478,16.739 -6.503,16.257 -7.285,15.535C-7.827,14.993 -8.248,14.33 -8.43,13.547C-8.49,13.187 -8.549,12.825 -8.549,12.464C-8.549,10.477 -7.225,8.851 -5.419,8.31L-5.419,4.155C-6.141,3.974 -6.743,3.613 -7.285,3.071C-7.827,2.529 -8.248,1.866 -8.43,1.084C-8.49,0.723 -8.549,0.362 -8.549,0.001C-8.549,-2.348 -6.622,-4.275 -4.214,-4.275C-1.806,-4.275 0.06,-2.348 0.06,0.001C0.06,0.362 0,0.723 -0.06,1.084C-0.242,1.866 -0.663,2.529 -1.205,3.071C-1.685,3.552 -2.348,3.974 -3.071,4.155L-3.071,8.369C-1.324,8.851 0,10.537 0,12.464" style="fill:rgb(68,171,137);fill-rule:nonzero;"/>
                            </g>
                            <g transform="matrix(5.55556,0,0,5.55556,918.889,184.321)">
                                <path d="M0,13.425C-0.723,13.905 -1.566,14.148 -2.41,14.148C-3.493,14.148 -4.575,13.725 -5.421,12.943C-5.599,12.762 -5.841,12.522 -5.961,12.281C-6.262,11.859 -6.443,11.438 -6.564,10.956C-6.745,10.354 -6.745,9.692 -6.624,9.03C-6.624,8.969 -6.564,8.85 -6.564,8.788L-10.177,6.68C-10.959,7.464 -12.042,7.885 -13.188,7.885C-14.331,7.885 -15.415,7.403 -16.197,6.68C-16.739,6.14 -17.16,5.477 -17.342,4.694C-17.402,4.332 -17.461,3.971 -17.461,3.61C-17.461,1.625 -16.137,-0.003 -14.331,-0.543C-13.97,-0.664 -13.549,-0.723 -13.188,-0.723C-12.765,-0.723 -12.404,-0.664 -12.042,-0.543C-10.236,-0.062 -8.912,1.625 -8.912,3.61C-8.912,3.971 -8.972,4.332 -9.031,4.694L-5.421,6.801C-5.238,6.621 -5.059,6.44 -4.817,6.319C-4.456,6.079 -4.035,5.839 -3.553,5.717C-2.89,5.537 -2.229,5.477 -1.566,5.658C-1.445,5.658 -1.326,5.717 -1.266,5.717C-0.302,6.019 0.542,6.621 1.143,7.464C2.467,9.391 1.987,12.101 0,13.425" style="fill:rgb(68,171,137);fill-rule:nonzero;"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </a>
            <ul class="ml-auto items-center gap-8 hidden lg:flex">
                <li>
                    <a @click.prevent="scrollToSection('features')" href="#">Features</a>
                </li>
                <li>
                    <a @click.prevent="scrollToSection('why')" href="#">Why</a>
                </li>
                <li>
                    <a @click.prevent="scrollToSection('what')" href="#">What</a>
                </li>
                <li>
                    <a @click.prevent="scrollToSection('how')" href="#">How</a>
                </li>
                <li>
                    <a @click.prevent="scrollToSection('who')" href="#">Who</a>
                </li>
                <li>
                    <a @click.prevent="scrollToSection('pricing')" href="#">Pricing</a>
                </li>
                <li>
                    <a @click.prevent="scrollToSection('contact')" href="#">Contact</a>
                </li>
                <li>|</li>
                <li class="font-bold">
                    <a @click.stop="$emit('toggle-insights', true)" href="#">INSIGHTS</a>
                </li>
            </ul>
            <button class="block md:hidden ml-auto focus:outline-none">
                <svg v-show="!open" @click="open = true" class="w-8 h-8 text-gray-800" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
                </svg>
                <svg v-show="open" @click="open = false" class="w-8 h-8 text-gray-800" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                </svg>
            </button>
            <div class="block md:hidden absolute bg-white shadow-lg absolute left-0 right-0 top-full -ml-4 -mr-4 p-4" v-show="open">
                <ul class="ml-auto items-center gap-8">
                    <li>
                        <a class="block px-2 py-1" @click.prevent="scrollToSection('features')" href="#">Features</a>
                    </li>
                    <li>
                        <a class="block px-2 py-1" @click.prevent="scrollToSection('why')" href="#">Why</a>
                    </li>
                    <li>
                        <a class="block px-2 py-1" @click.prevent="scrollToSection('what')" href="#">What</a>
                    </li>
                    <li>
                        <a class="block px-2 py-1" @click.prevent="scrollToSection('how')" href="#">How</a>
                    </li>
                    <li>
                        <a class="block px-2 py-1" @click.prevent="scrollToSection('who')" href="#">Who</a>
                    </li>
                    <li>
                        <a class="block px-2 py-1" @click.prevent="scrollToSection('pricing')" href="#">Pricing</a>
                    </li>
                    <li>
                        <a class="block px-2 py-1" @click.prevent="scrollToSection('contact')" href="#">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>
<script>
    export default {
        data(){
            return {
                sticky: false,
                open: false
            }
        },
        methods: {
            scrollToSection(name){
                document.querySelector('[data-section="'+name+'"]').scrollIntoView({behavior: 'smooth'})
            }
        },
        mounted(){
            window.addEventListener('scroll', (e) => {
                var top  = window.pageYOffset || document.documentElement.scrollTop
                if(top > 100){
                    this.sticky = true
                } else {
                    this.sticky = false
                }
            });
        }
    }
</script>