<template>
    <button @click="scrollToTop" v-show="sticky" class="bg-green-400 w-12 h-12 block fixed right-5 bottom-5 flex items-center justify-center rounded">
        <svg class="w-6 h-6 text-white" viewBox="0 0 24 24">
            <path fill="currentColor" d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z" />
        </svg>
    </button>
</template>
<<script>
    export default {
        data(){
            return {
                sticky: false
            }
        },
        methods: {
            scrollToTop(){
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
        mounted(){
            window.addEventListener('scroll', (e) => {
                var top  = window.pageYOffset || document.documentElement.scrollTop
                if(top > 600){
                    this.sticky = true
                } else {
                    this.sticky = false
                }
            });
        }
    }
</script>