<template>
    <div>
        <VueSlickCarousel v-bind="sliderSettings">
            <div v-for="(image, index) in images" class="p-4">
                <a class="block" href="#" @click.prevent.stop="currentImage = index">
                    <div class="rounded p-3 bg-white shadow-lg">
                        <img class="rounded" :src="image.thumb">
                    </div>
                </a>
            </div>
        </VueSlickCarousel>
        <div class="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-20" v-show="currentImage != null">
            <div class="absolute top-0 right-0 p-5">
                <a href="#" @click.prevent.stop="currentImage = null">
                    <svg class="w-8 h-8 text-white" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                    </svg>
                </a>
            </div>
            <div class="container m-auto">
                <VueSlickCarousel v-bind="sliderLightboxSettings" ref="lightbox">
                    <div v-for="image in images" class="text-center">
                        <img class="rounded w-2/3" :src="image.thumb">
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>
<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    export default {
        props: ['images'],
        data(){
            return {
                currentImage: null,
                sliderSettings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    responsive: [
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                },
                sliderLightboxSettings: {
                    arrows: true,
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }   
        },
        watch: {
            currentImage(){
                if(this.currentImage != null){
                    this.$refs.lightbox.goTo(this.currentImage)
                }
            }
        },
        components: {
            VueSlickCarousel
        }
    }
</script>